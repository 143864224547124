import styled from "styled-components";
import { vSpacing } from "~/styles/mixins";
import { rem, smallerThan } from "~/styles/theme";

const BigCopy = styled.div<any>`
  position: relative;
  font-size: ${(p) => p.size || "3vw"};
  /* font-weight: bold; */

  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";

  > * {
    font-weight: inherit;
    font-size: inherit;
  }

  ${vSpacing};
  line-height: 1.1;
  color: ${(p) => p.color || p.theme.colors.dark};

  /* on mobile, fix the size as per the design */
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    font-size: ${(p) => (!p.quote ? rem("25px") : `${parseInt(p.size) * 0.6}vh`)};
  }

  p {
    margin: 0;
    padding: 0;
    /* z-index: ${(p) => p.theme.z.body}; */
    strong {
      /* font-weight: normal; */
      font-weight: inherit;
      background-image: linear-gradient(
        to right,
        ${(p) => p.highlightColor ?? p.theme.colors.primary} 100%,
        transparent 100%
      );
      background-position: 0 0.71em;
      background-repeat: repeat-x;
      background-size: 1em 0.25em;
      /*
      position: relative;
      z-index: ${(p) => p.theme.z.body};
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: -0.1em;
        right: -0.1em;
        bottom: 0.1em;
        height: 0.2em;
        background-color: ${(p) => p.highlightColor ?? p.theme.colors.primary};
        pointer-events: none;
      }
      */
    }
    padding-top: ${(p) => p.theme.sizes.vSpacing * 2}rem;
    &:nth-child(1) {
      padding-top: 0;
    }
  }
`;

export default BigCopy;
