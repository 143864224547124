import { useContext } from "react";
import styled from "styled-components";
import Anchor from "~/components/Anchor";
import BigCopy from "~/components/BigCopy";
import Block, { BlockContent } from "~/components/Block";
import Title from "~/components/Title";
import { getCommonSliceProps, getRichText } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { ThemeContext } from "~/lib/ThemeContext";
import { paragraph } from "~/styles/mixins";
import { parseFontStyle, parseFontWeight, smallerThan } from "~/styles/theme";

const Text = styled.div`
  color: ${(p) => p.color || p.theme.colors.dark};
  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";
  p {
    ${paragraph};
    /* margin: ${(p) => p.theme.sizes.vSpacing}rem 0; */
    padding-bottom: ${(p) => p.theme.sizes.vSpacing}rem;
    width: 50%;

    &:first-child {
      padding-top: ${(p) => p.theme.sizes.vSpacing}rem;
    }

    ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
      width: 80%;
    }

    ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
      width: auto;
    }
  }
`;

function TitleAndText(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor = currentTheme.background,
    color = currentTheme.foreground,
    animateIn = false,

    highlightColor = currentTheme.highlight,
    highlightOutline,
    anchorText,
    anchorPreText,
    anchorId,
    titleType,
    line1 = null,
    line2 = null,
    line3 = null,
    titleFontStyle,
    titleFontWeight,
    size = 1,
    text,
    textSize = "Normal",
    fontWeight = "400",
    fontStyle = "",
  } = props;

  const TextComponent = textSize === "normal" ? Text : BigCopy;

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        {anchorText && (
          <Anchor id={anchorId} pre={anchorPreText} color={color}>
            {anchorText}
          </Anchor>
        )}
        <Title
          highlightColor={highlightColor}
          highlightOutline={highlightOutline}
          type={titleType}
          line1={line1}
          line2={line2}
          line3={line3}
          size={size}
          color={color}
          fontWeight={titleFontWeight}
          fontStyle={titleFontStyle}
          animateIn={animateIn}
        />
        {text && (
          <TextComponent fontWeight={fontWeight} fontStyle={fontStyle} color={color}>
            {text}
          </TextComponent>
        )}
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  // console.log(primary);
  // console.log('PRIMARY', primary);

  const props = {
    ...getCommonSliceProps(slice),

    titleType: primary.title_type,
    titleFontStyle: parseFontStyle(primary.title_font_style, "Normal"),
    titleFontWeight: parseFontWeight(primary.title_font_weight, "Normal"),
    line1: getRichText(primary.line_1),
    line2: getRichText(primary.line_2),
    line3: getRichText(primary.line_3),

    text: getRichText(primary.text),
    textSize: primary.text_size && primary.text_size.toLowerCase(),

    fontStyle: parseFontStyle(primary.font_style, "Normal"),
    fontWeight: parseFontWeight(primary.font_weight, "Normal"),
  };
  return props;
}

export default connectSlice(mapSliceToProps)(TitleAndText);
